<template>
	<div>
    <breadcrumb name="Home" title="Home Dashboard" subtitle="View"  />

  </div>
</template>
<script>
  import Breadcrumb from "../components/general/breadcrumb";
  export default {
    components: {Breadcrumb}
  }
</script>
